import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDomainData } from "../services";
import { setDomainData } from "../state/actions/domainData";
import { getFormat } from "../utils";
/* eslint-disable */
export const useDomainData = async() => {
    const dispatch = useDispatch()
    const domainData = useSelector(state => state.domainData)
    console.log(domainData)
    useEffect(() => {
        
            // if(domainData.data != null){
            //     console.log("Datafound");
            // }
            // else {

            // const domain = getFormat("tablesdirect.co.uk", false)
            const domain = getFormat(window.location.hostname, false)
            fetch('https://any-dns.co.uk/aw_api/api/data?domain=' + domain,{
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(response => response.json())
            .then(data => {
                console.log("data",data)

                //Get css data
                const css = data.find((item) => item.DT_Topic === "CSS");
                const columnRight = data.find((item) => item.DT_Topic === "Default_ColRight");
                const columnLeft = data.find((item) => item.DT_Topic === "Default_ColLeft");
                console.log("COL Data",columnRight, columnLeft)
                const defaultColumn = {
                    right: {
                        width: columnRight?.DT_Setting,
                        content: columnRight?.DT_Setting_URL
                    },
                    left: {
                        width: columnLeft?.DT_Setting,
                        content: columnLeft?.DT_Setting_URL
                    },

                }
                //Get and process menu items

                const processItems = (items) => {
                    const newObj = {};
                
                    items.forEach((item) => {
                        const { DT_Level1, DT_Level2, DT_Level3, DT_Topic } = item;
                
                        // Check if DT_Topic starts with "Menu"
                        if (DT_Topic.startsWith("Menu")) {
                            // Create an object for DT_Topic if it doesn't exist
                            if (!newObj[DT_Topic]) {
                                newObj[DT_Topic] = [];
                            }

                            const topicArray = newObj[DT_Topic];
                
                            
                                topicArray.push(item);

                
                            const topicCopy = [...topicArray];
                
                            topicCopy.forEach((item) => {
                                const {DT_Level1, DT_Level2, DT_Level3 } = item;
                                if (DT_Level3 !== null) {
                                    const parentItem = topicArray.find((parent) => parent.DT_Level1 === DT_Level1 && parent.DT_Level2 === DT_Level2 && parent.DT_Level3 === null);
                                    if (parentItem) {
                                        if (!parentItem.items) {
                                            parentItem.items = [];
                                        }
                                        parentItem.items.push(item);
                                        // Remove the item from the original array
                                        topicArray.splice(topicArray.indexOf(item), 1);
                                    }
                                }
                            });
                        }
                    });
                
                    // Sort each array
                    Object.keys(newObj).forEach((key) => {
                        newObj[key] = newObj[key]
                            .filter(item => item.DT_Level1 === '01') // Filter DT_Level1 == "01"
                            .sort((a, b) => a.DT_Level2 - b.DT_Level2) // Sort by DT_Level2 ascending
                            .concat(newObj[key]
                                .filter(item => item.DT_Level1 === '02') // Filter DT_Level1 == "02"
                                .sort((a, b) => a.DT_Level2 - b.DT_Level2) // Sort by DT_Level2 ascending
                            );
                    });
                
                    return newObj;
                };


                const processedItems = processItems(data);
                console.log("processed Items::",processedItems)
                processedItems.columns = defaultColumn
                processedItems.css = css.DT_Setting_URL
                // processedItems.pages = data.filter(item => { return item.DT_Topic === 'Menu_Main' || item.DT_Topic === 'Menu_Top';});
                processedItems.pages = data.filter(item => item.DT_Topic.startsWith('Menu'))
                console.log("processed items", processedItems)
                dispatch(setDomainData(processedItems))
            })

            .catch(error => console.error(error));
        // };
    }, 
    []);
};