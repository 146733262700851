import React, { useEffect, useRef } from 'react';
import { Route, Routes, BrowserRouter, Navigate, useNavigate, useLocation } from 'react-router-dom';
import Domain from '../domainSale';
import DynamicPage from '../dynamicPage';
import paths from './paths';
import Search from '../searchResult';
import { useDomainData } from '../../hooks/useDomainData';
import useDynamicCSS from '../../hooks/useDynamicCSS';
import { useSelector } from 'react-redux';
// import NotFound from '../notFound';
import { awcg_domain } from '../../utils';
// import PrivateRoute from './PrivateRoute';




const RouterComponent = () => {
  useDomainData()
  const loaded = useSelector(state => state.domainData.data)
  // const pages = loaded?.pages
  // const cssFilePath = loaded?.css
  // const columns = loaded?.columns
  // useDynamicCSS("master.css");
  // useDynamicCSS(cssFilePath);


  // useEffect(() => {
  //   // Assume valid and aw_domain are defined somewhere else in your code
  //   let valid = false;
  //   // let aw_domain = ...;
    
  //   if(valid !== true) {
  //     // If the subdomain is not valid, redirect to the main domain
  //     window.location.href = `http://${awcg_domain}${window.location.pathname}`;
  //   }
  // }, []); // Empty array ensures this runs only on first render
  
  
  
  if (loaded) {
    return (
      <BrowserRouter>
        <PagesComponent />
      </BrowserRouter>
    );
  }
  else {
    return (
      <p>Loading...</p>
    )
  }
}

const PagesComponent = () =>{
  const loaded = useSelector(state => state.domainData.data)
  const pages = loaded?.pages
  const cssFilePath = loaded?.css
  const columns = loaded?.columns
  useDynamicCSS("master.css");
  useDynamicCSS(cssFilePath);
  const location = useLocation();

  const isSubdomain = window.location.hostname.endsWith(`.${awcg_domain}`) && window.location.hostname !== awcg_domain;
  if(isSubdomain){
    window.location.href = `https://${awcg_domain}${location.pathname}${location.search}`;
  }
  
  
  // const location = useLocation();
  // const firstPath = useRef(location.pathname);

  // if(firstPath.current == "/" && location.pathname !== "/"){
  //   firstPath.current = location.pathname
  // }
  // console.log("firstPath::",firstPath.current, "current:",location.pathname, "search params:", location.search)
  // useEffect(() => {
  //   const currentDomain = window.location.hostname;
    
  //   // Check if the current domain is a subdomain of awcg_domain
  //   const isSubdomain = currentDomain.endsWith(`.${awcg_domain}`) && currentDomain !== awcg_domain;
  //   // Assume valid and aw_domain are defined somewhere else in your code
  //   let valid = false
  //   if(isSubdomain) {
  //     if(valid !== true) {
  //       if(firstPath.current !== location.pathname && firstPath.current !== "/"){
  //         console.log("CHANGE OF ROUTE DETECTED ON INVALID SUBDOMAIN:", location.pathname)
  //         // If the subdomain is not valid, redirect to the main domain
  //         window.location.href = `https://${awcg_domain}${location.pathname}${location.search}`;
  //       }
  //     }
  //   }
  // }, [location]); // This effect runs whenever 'location' changes

  return(
          <Routes>
            {pages.map((page, index) => (
              <Route key={index} path={page.DT_Setting_URL} element={<DynamicPage centerJSX={page.DT_Content_EN} title={page.DT_Setting} columns={(page.DT_ColumnLeftWidth, page.DT_ColumnLeftContent, page.DT_ColumnRightWidth, page.DT_ColumnRightContent )} defaultColumns={columns}/>} exact />
            ))}
            <Route path="/" element={<Navigate replace to={`${pages[0].DT_Setting_URL}`} />} />
            <Route exact path={paths.SEARCH} element={<Search/>} />
            <Route exact path={paths.DOMAIN} element={<Domain/>} />
  
            <Route path="*" element={<Navigate replace to={`${pages[0].DT_Setting_URL}`} />} />
          </Routes>
  )
}

export default RouterComponent;
